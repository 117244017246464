import { combineReducers } from "redux";
import UserReducer from "./UserReducer";
import CredentialReducer from "./CredentialsReducer"
import AlertReducer from "./AlertReducer"

const RootReducer = combineReducers({
  user: UserReducer,
  credential: CredentialReducer,
  alert: AlertReducer
});

export default RootReducer;
