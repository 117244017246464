import React from "react";

import 'react-circular-progressbar/dist/styles.css';


export default function StepR(props)  {

  

  //const dispatch = useDispatch()

  
  

    return (
        <>
        {/* <div className="col-sm-12 col-md-12 col-lg-12 align-self-center" style={{paddingTop: '2em'}}>
            {props.arrowup && 
            <img className="img" src="img/control/arrow-gif-blue-down.gif" alt="Swipe up" width="100" height="100" style={ImgRotate}/>
            }
        </div>  */}
        <div className="content-area">


            <div className="grid grid-container--lg gutter--md-xl gutter-row--md">
                <div className="column--xs-12 column--md-4">
                    <h3 className="main-heading">{props.title}</h3>
                    <br />
                    {props.desc}

                </div>
                <div className="column--xs-12 column--md-8">
                    {/* <img className="img" src={props.img}  alt="MOE" width="800" height="800"/> */}
                    <a href={props.anchor}>
                        <img className="img" src={props.img}  alt="MOE" width="800" height="800"/>
                    </a>
                </div>
            </div>


        </div>
        {/* <div className="col-sm-12 col-md-12 col-lg-12">
                {props.arrowdown && 
                <img className="img" src="img/control/arrow-gif-blue-down.gif" alt="Swipe down" width="100" height="100"/>
                } */}
            {/* <div className="col-sm-1 col-md-1 col-lg-1">

                <CircularProgressbar value={'10'} text={`10%`} width="120" height="120"/>
            </div>  */}
        {/* </div>  */}

    </>
        
      );
  };

