import React from 'react';

import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import history from "./history";
import Routes from "./RootRoutes";
import { Store } from "./redux/Store";
// import Auth from "./auth/Auths";
// import AppContext from "./appContext";
// import AuthGuard from "./auth/AuthGuard";
import { ThemeProvider } from '@material-ui/core/styles'

import theme from './sharedcomponents/theme'

function App() {
  return (

    
    <ThemeProvider theme={theme}>
      <Provider store={Store}>

          {/* <Auth> */}
            <Router history={history}>
              <div className="desktop-menu-layout mobile-menu-layout sticky-footer-layout">
                {/* <AuthGuard> */}

                    <Routes />

              </div>
              {/* </AuthGuard> */}
            </Router>
          {/* </Auth> */}

      </Provider>
    </ThemeProvider>


    
  );
}

export default App;
