import React from "react";
//import { Breadcrumb, SimpleCard } from "matx";
//import history from "history.js";
//import {insertPatient} from '../../redux/actions/PatientActions'
import Fullpage, { FullPageSections, FullpageSection, FullpageNavigation, FullpageContext } from '@ap.cx/react-fullpage'
import { useTranslation } from 'react-i18next'
//import { Link } from 'react-router-dom'

import EDMSetup from "../../sharedcomponents/EDMSetup"
import Welcome from "./shared/Welcome"
import StepL from "../../sharedcomponents/StepL"




export default function Home()  {

    const { t } = useTranslation()
    

    
    const StepStyle = {
        //paddingTop: '6em',
        // display: 'flex', 
        //width: '100vh', 
        //height: '100vh', 
        justifyContent: 'center', 
        alignItems: 'center'
    }

    // const PageStyle = {

    //     alignItems: 'center', 
    //     justifyContent: 'center',
    //     display: 'flex',
    //     flexDirection: 'column',
    // }
    
    const ArrowUp ={
        position: 'fixed',
        top: '0',
        marginTop: '5em',
        //width: '100%',
        //height: '0',
        borderRadius: '50%',
        
        zIndex: '2',
        border: 'none',
        outline:'none',
        "&:hover": {
            'outline': "none"
          }
    }

    const ArrowDown ={
        position: 'fixed',
        bottom: '0',
        marginBottom: '1em',
        //width: '100%',
        //height: '0',
        borderRadius: '50%',
        border: 'none',
        outline:'none',
        zIndex: '2',
        "&:hover": {
            'outline': "none"
          }
    }






    return (
        <>
      


        
        <Fullpage>
            <FullpageContext.Consumer>
                {(ctx) => (
                    <>
                        {ctx.number > 0 &&

                            <div className="col-xs-12 col-md-12" style={{display: 'flex', justifyContent: 'center', alignItems:'center'}}>
                                <button type="button" className="btn btn-sq btn-clock btn-primary" data-togle= "modal" title={t("Shared.back")} style={ArrowUp} onClick={() => ctx.goto(ctx.slides[ctx.number -1], true)}> 
                                    <span className="fa fa-chevron-circle-up" aria-hidden="true"></span>
                                </button>

                            </div>

                        }

                        {ctx.number < 23 &&
                            <div className="col-xs-12 col-md-12" style={{display: 'flex', justifyContent: 'center', alignItems:'center'}}>
                                <button type="button" className="btn btn-sq btn-clock btn-primary" data-togle= "modal" title={t("Shared.next")} style={ArrowDown} onClick={() => ctx.goto(ctx.slides[ctx.number +1], true)}> 
                                    <span className="fa fa-chevron-circle-down" aria-hidden="true"></span>
                                </button>
                            </div>
                        }


                    <FullpageNavigation />
                    <FullPageSections>
                        <FullpageSection id="#1">
                            <Welcome ctx={ctx}/>

                        </FullpageSection>

                        <FullpageSection style={{ alignItems: 'center', justifyContent: 'center',paddingTop: '7em'}}>
                            <StepL style={StepStyle}
                                anchor={"#slide" + (ctx.number +1)}
                                title={t("Step1.Title")}
                                desc={<>
                                    <div className="dos-and-donts">
                                        <div className="dotted-list do-list" style={{justifyContent:"center"}}>
                                        {/* <h3>First Thing first</h3> */}
                                        <br />
                                            <ol>
                                                <li>
                                                {t("Step1.Opt1")}
                                                    
                                                </li>
                                                <li>
                                                    {t("Step1.Opt2")}
                                                </li>
                                                <li>
                                                    {t("Step1.Opt3")}
                                                </li>
                                            </ol>
                                        </div>
                                    </div>
                                    </>
                                }
                                img="img/organize.svg"
                                arrowdown="true"
                                arrowup="true"
                            />

                        </FullpageSection>

                                              
                        <EDMSetup ctx={ctx}/>

                       


                    </FullPageSections>
                    </>
                )}
            </FullpageContext.Consumer>
        </Fullpage>




        </>
        
      );
  };

