
import api from "../../services/api"

export const GET_CREDENTIALS_DETAIL = "GET_CREDENTIALS_DETAIL";




export function getCredential(data) {
  return (dispatch) => {
    dispatch({
      type: "GET_CREDENTIALS_DETAIL",
      payload: {
        loading: true,
        error: false
      }
    })
    api.post('mycredential', data)
      .then(response => {
        dispatch({
          type: GET_CREDENTIALS_DETAIL,
          payload: {...response.data, loading: false}
        })
      })
      .catch(error => {
        dispatch({
            type: "GET_CREDENTIALS_DETAIL",
            payload: {
              loading: false,
              error: true
            }
          })
      })
  }
}