import React from "react";

import { FullpageSection } from '@ap.cx/react-fullpage'
import { useTranslation } from 'react-i18next'


import StepL from "../../sharedcomponents/StepL"
import StepR from "../../sharedcomponents/StepR"




export default function LaptopRebuild()  {

    const { t } = useTranslation()
    

    
    const StepStyle = {
        //paddingTop: '6em',
        // display: 'flex', 
        //width: '100vh', 
        //height: '100vh', 
        justifyContent: 'center', 
        alignItems: 'center'
    }

    const PageStyle = {

        alignItems: 'center', 
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
    }
    






    return (
        <>
      


            <FullpageSection style={PageStyle}>
                <StepL style={StepStyle}
                    //anchor={"#slide" + (ctx.number +1)}
                    title={t("RebuildSteps1.Title")}
                    desc={<>
                        <div className="dos-and-donts">
                            <div className="dotted-list do-list" style={{justifyContent:"center"}}>
                            <p>{t("RebuildSteps1.Desc")}<b>{t("Shared.next")}</b></p>
                            </div>
                        </div>
                        </>
                    }
                    img="img/rebuild/steps/1.png"

                />

            </FullpageSection>
            <FullpageSection style={PageStyle}>
                <StepR style={StepStyle}
                    //anchor={"#slide" + (ctx.number +1)}
                    title={t("RebuildSteps2.Title")}
                    desc={<>
                        <div className="dos-and-donts">
                            <div className="dotted-list do-list" style={{justifyContent:"center"}}>
                            <p>{t("RebuildSteps2.Desc")}</p>
                            </div>
                        </div>
                        </>
                    }
                    img="img/rebuild/steps/2.png"

                />

            </FullpageSection>
            <FullpageSection style={PageStyle}>
                <StepL style={StepStyle}
                    //anchor={"#slide" + (ctx.number +1)}
                    title={t("RebuildSteps3.Title")}
                    desc={<>
                        <div className="dos-and-donts">
                            <div className="dotted-list do-list" style={{justifyContent:"center"}}>
                            <p>{t("RebuildSteps3.Desc")}<b>{t("Shared.next")}</b></p>
                            </div>
                        </div>
                        </>
                    }
                    img="img/rebuild/steps/3.png"

                />

            </FullpageSection>
            <FullpageSection style={PageStyle}>
                <StepR style={StepStyle}
                    //anchor={"#slide" + (ctx.number +1)}
                    title={t("RebuildSteps4.Title")}
                    desc={<>
                        <div className="dos-and-donts">
                            <div className="dotted-list do-list" style={{justifyContent:"center"}}>
                            <p>{t("RebuildSteps4.Desc")}</p>
                            </div>
                        </div>
                        </>
                    }
                    img="img/rebuild/steps/4.png"

                />

            </FullpageSection>
            <FullpageSection style={PageStyle}>
                <StepL style={StepStyle}
                    //anchor={"#slide" + (ctx.number +1)}
                    title={t("RebuildSteps5.Title")}
                    desc={<>
                        <div className="dos-and-donts">
                            <div className="dotted-list do-list" style={{justifyContent:"center"}}>
                            <p>{t("RebuildSteps5.Desc")}</p>
                            </div>
                        </div>
                        </>
                    }
                    img="img/rebuild/steps/5.png"

                />

            </FullpageSection>
            <FullpageSection style={PageStyle}>
                <StepR style={StepStyle}
                    //anchor={"#slide" + (ctx.number +1)}
                    title={t("RebuildSteps7.Title")}
                    desc={<>
                        <div className="dos-and-donts">
                            <div className="dotted-list do-list" style={{justifyContent:"center"}}>
                            <p>{t("RebuildSteps7.Desc")}</p>
                            </div>
                        </div>
                        </>
                    }
                    img="img/rebuild/steps/7.png"

                />

            </FullpageSection>
            <FullpageSection style={PageStyle}>
                <StepL style={StepStyle}
                    //anchor={"#slide" + (ctx.number +1)}
                    title={t("RebuildSteps8.Title")}
                    desc={<>
                        <div className="dos-and-donts">
                            <div className="dotted-list do-list" style={{justifyContent:"center"}}>
                            <p>{t("RebuildSteps8.Desc")}<b>{t("Shared.apply")}</b></p>
                            </div>
                        </div>
                        </>
                    }
                    img="img/rebuild/steps/8.png"

                />

            </FullpageSection>
            <FullpageSection style={PageStyle}>
                <StepR style={StepStyle}
                    //anchor={"#slide" + (ctx.number +1)}
                    title={t("RebuildSteps9.Title")}
                    desc={<>
                        <div className="dos-and-donts">
                            <div className="dotted-list do-list" style={{justifyContent:"center"}}>
                            <p>{t("RebuildSteps9.Desc")}</p>
                            </div>
                        </div>
                        </>
                    }
                    img="img/rebuild/steps/9.png"

                />

            </FullpageSection>
            <FullpageSection style={PageStyle}>
                <StepL style={StepStyle}
                    //anchor={"#slide" + (ctx.number +1)}
                    title={t("RebuildSteps10.Title")}
                    desc={<>
                        <div className="dos-and-donts">
                            <div className="dotted-list do-list" style={{justifyContent:"center"}}>
                            <p>{t("RebuildSteps10.Desc")}<b>{t("Shared.next")}</b></p>
                            </div>
                        </div>
                        </>
                    }
                    img="img/rebuild/steps/10.png"

                />

            </FullpageSection>
            <FullpageSection style={PageStyle}>
                <StepR style={StepStyle}
                    //anchor={"#slide" + (ctx.number +1)}
                    title={t("RebuildSteps11.Title")}
                    desc={<>
                        <div className="dos-and-donts">
                            <div className="dotted-list do-list" style={{justifyContent:"center"}}>
                            <p>{t("RebuildSteps11.Desc")}</p>
                            </div>
                        </div>
                        </>
                    }
                    img="img/rebuild/steps/11.png"

                />

            </FullpageSection>

        </>
        
      );
  };

