import React from "react"
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import Header from "./sharedcomponents/Header"
// import Menu from "./sharedcomponents/Menu"
import Welcome from './views/welcome/Main'
import Recover from './views/recover_creds/Main'
import Rebuild from './views/laptop_rebuild/Main'


export default function Routes() {
  return(

      <BrowserRouter>
        <Header />
        <main className="page-wrapper">
          <Switch>

              <Route path="/" exact component={Welcome} />
              <Route path="/credentials" exact component={Recover} />
              <Route path="/rebuild" exact component={Rebuild} />
              <Redirect exact from='/welcome' to='/'/>
          </Switch>
        </main>
      </BrowserRouter>
  )
}
