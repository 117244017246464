import React from "react";
import "./modal.css";
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap';


export default function NTTModal(props)  {
    const { t } = useTranslation()


    return (



    <Modal style={{"display": "block" }}show={props.modalShow}  animation={false} onHide={props.handleClose}>
        <Modal.Header closeButton>
        <Modal.Title>{t("RecCredentials.Mod_Title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

            <>

                <div className="grid grid--2-cols gutter--md-xl gutter-row--md">
                    <div className="column--xs-2 column--md-1">
                        <img className="img" src="img/help_servicetag/dell.jpeg"  alt="Dell"/>
                    </div>
                    <div className="column--xs-2 column--md-1">
                        <h3 className="main-heading">Dell</h3>
                        <span>{t("RecCredentials.Mod_Dell")}</span>
                    </div>
                {/* </div>
                <div className="grid grid--2-cols gutter--md-xl gutter-row--md"> */}
                    <div className="column--xs-2 column--md-1">
                        <h3 className="main-heading">Lenovo</h3>
                        <span>{t("RecCredentials.Mod_Lenovo")}</span>
                    </div>
                    <div className="column--xs-2 column--md-1">
                        <img className="img" src="img/help_servicetag/lenovo.jpg"  alt="Lenovo"/>
                    </div>
                </div>
            </>
            
        </Modal.Body>
        <Modal.Footer>
            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={props.handleClose}>{t("Shared.close")}</button>
        </Modal.Footer>
    </Modal>
    )


}