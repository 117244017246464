import {
    GET_CREDENTIALS_DETAIL,

  } from "../actions/CredentialsActions";
  
  const initialState = {

    credentialDetail: {}
  };
  
  const CredentialReducer = function(state = initialState, action) {
    switch (action.type) {
      
      case GET_CREDENTIALS_DETAIL: {
        // console.log(action)
        return {
          ...state,
          credentialDetail: {...action.payload},
  

        }
        
      }


      default: {
        return {
          ...state
        };
      }
    }
  };
  
  export default CredentialReducer;
  