import React from "react";

import { FullpageSection } from '@ap.cx/react-fullpage'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import StepL from "./StepL"
import StepR from "./StepR"




export default function EDMSetup(object)  {

    const { t } = useTranslation()
    

    
    const StepStyle = {
        //paddingTop: '6em',
        // display: 'flex', 
        //width: '100vh', 
        //height: '100vh', 
        justifyContent: 'center', 
        alignItems: 'center'
    }

    const PageStyle = {

        alignItems: 'center', 
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
    }
    






    return (
        <>
      


                    <FullpageSection style={PageStyle}>
                        
                        <StepL style={StepStyle}
                            anchor={"#slide" + (object.ctx.number +1)}
                            title={t("Step3.Title")}
                            desc={<p>{t("Step3.Desc")}<b>{t("Shared.next")}</b>.</p>}
                            img="img/steps/MOEStep2.png"
                            arrowdown="true"
                            arrowup="true"
                        />

                    </FullpageSection>


                    <FullpageSection style={PageStyle}>
                        <StepR style={StepStyle}
                            anchor={"#slide" + (object.ctx.number +1)}
                            title={t("Step4.Title")}
                            desc={<p>{t("Step4.Desc")}<Link to="/credentials"> {t("Shared.clickhere")}</Link>.</p>}
                            img="img/steps/MOEStep3.png"
                            arrowdown="true"
                            arrowup="true"
                        />

                    </FullpageSection>

                    <FullpageSection style={PageStyle}>
                        <StepL style={StepStyle}
                            title={t("Step5.Title")}
                            desc={<p>{t("Step5.Desc")}<b>{t("Shared.next")}</b>.</p>}
                            img="img/steps/MOEStep5.png"
                            arrowdown="true"
                            arrowup="true"
                        />

                    </FullpageSection>

                    <FullpageSection style={PageStyle}>

                        <StepR style={StepStyle}
                            title={t("Step6.Title")}
                            desc={
                                <div>
                                    <p>{t("Step6.Desc")}</p>
                                    <p>{t("Step6.Desc2")}<b>{t("Shared.next")}</b>.</p>
                                </div>
                            }
                            img="img/steps/MOEStep6.png"
                            arrowdown="true"
                            arrowup="true"
                        />


                    </FullpageSection>


                    <FullpageSection style={PageStyle}>

                        <StepL style={StepStyle}
                            title={t("Step7.Title")}
                            desc={
                                <div>
                                    <p>{t("Step7.Desc")}</p>
                                        <div className="row" style={{    display: 'flex',justifyContent: 'space-around'}}>
                                        {/* <div className="col"> */}

                                            <a title="Google Play" href="https://play.google.com/store/apps/details?id=com.azure.authenticator">
                                                <img border="0" alt="Google Play" src="img/control/play-store.png" width="100" height="100" />
                                            </a>

                                        {/* </div> */}
                                        {/* <div className="col-sm-4 col-md-4 col-lg-4"> */}
                                            <a title="App Store" href="https://apps.apple.com/br/app/microsoft-authenticator/id983156458">
                                                <img border="0" alt="AppStore" src="img/control/app-store.png" width="100" height="100" />
                                            </a>
                                        {/* </div> */}
                                    </div>
                                    <div className="dos-and-donts">
                                    <div className="dotted-list do-list" style={{justifyContent:"center"}}>
                                    {/* <h3>First Thing first</h3> */}
                                    <br />
                                        <ol>
                                            <li>
                                            {t("Step7.Opt1")}
                                                
                                            </li>
                                            <li>
                                                {t("Step7.Opt2")}
                                            </li>
                                            <li>
                                                <>{t("Step7.Opt3")} <b>{t("Shared.next")}</b>.</>
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                                </div>
                                
                            }
                            img="img/steps/MOEStep7.png"
                            arrowdown="true"
                            arrowup="true"
                        />

                    </FullpageSection>

                    <FullpageSection style={PageStyle}>

                        <StepR style={StepStyle}
                            title={t("Step8.Title")}
                            desc={<>
                                <p>{t("Step8.Desc")}</p>
                                <p>{t("Step8.Desc2")}<b>{t("Shared.next")}</b>.</p>
                                </>  
                             }
                            img="img/steps/MOEStep8.png"
                            arrowdown="true"
                            arrowup="true"
                        />


                    </FullpageSection>

                    <FullpageSection style={PageStyle}>

                        <StepL style={StepStyle}
                            title={t("Step9.Title")}
                            desc={
                            <div>
                                <p>{t("Step9.Desc")}</p>
                            </div>
                            }
                            img="img/steps/MOEStep9.png"
                            arrowdown="true"
                            arrowup="true"
                        />

                    
                    </FullpageSection>

                    <FullpageSection style={PageStyle}>

                        <StepR style={StepStyle}
                            title={t("Step10.Title")}
                            desc={
                            <div>
                                <p>{t("Step10.Desc")}</p>
                            </div>
                            }
                            img="img/steps/MOEStep10.png"
                            arrowdown="true"
                            arrowup="true"
                        />


                    </FullpageSection>

                    <FullpageSection style={PageStyle}>

                        <StepL style={StepStyle}
                            title={t("Step11.Title")}
                            desc={
                            <div>
                                <p>{t("Step11.Desc")}</p>
                            </div>
                            }
                            img="img/steps/MOEStep11.png"
                            arrowdown="true"
                            arrowup="true"
                        />


                    </FullpageSection>

                    <FullpageSection style={PageStyle}>

                        <StepR style={StepStyle}
                            title={t("Step12.Title")}
                            desc={
                            <div>
                                <p>{t("Step12.Desc")}</p>
                                
                            </div>
                            }
                            img="img/steps/MOEStep12.png"
                            arrowdown="true"
                            arrowup="true"
                        />


                    </FullpageSection>

                    <FullpageSection style={PageStyle}>

                        <StepL style={StepStyle}
                            title={t("Step13.Title")}
                            desc={
                            <div>
                                <p>{t("Step13.Desc")}</p>
                                <p>{t("Step13.Desc2")}<b>{t("Shared.getstarted")}</b>.</p>
                            </div>
                            }
                            img="img/steps/Okta1.png"
                            arrowdown="true"
                            arrowup="true"
                        />


                    </FullpageSection>

                    <FullpageSection style={PageStyle}>

                        <StepR style={StepStyle}
                            title={t("Step14.Title")}
                            desc={
                            <div>
                                <p>{t("Step14.Desc")}<b>{t("Shared.next")}</b>.</p>
                            </div>
                            }
                            img="img/steps/Okta3.png"
                            arrowdown="true"
                            arrowup="true"
                        />
                    </FullpageSection>


                    <FullpageSection style={PageStyle}>

                        <StepL style={StepStyle}
                            title={t("Step15.Title")}
                            desc={
                            <div>
                                <p>{t("Step15.Desc")}<a href="https://comms.services.global.ntt/GetYourNumericID" target="_blank" rel="noreferrer">{t("Shared.clickhere")}</a>.</p>
                            </div>
                            }
                            img="img/steps/OktaID.png"
                            arrowdown="true"
                            arrowup="true"
                        />
                    </FullpageSection>

                    <FullpageSection style={PageStyle}>

                        <StepR style={StepStyle}
                            title={t("Step16.Title")}
                            desc={
                            <div>
                                <p>{t("Step16.Desc")}</p>
                            </div>
                            }
                            img="img/steps/Okta4.png"
                            arrowdown="true"
                            arrowup="true"
                        />
                    </FullpageSection>

                    <FullpageSection style={PageStyle}>

                        <StepL style={StepStyle}
                            title={t("Step17.Title")}
                            desc={
                            <div>
                                <p>{t("Step17.Desc")}</p>
                            </div>
                            }
                            img="img/steps/Okta5.png"
                            arrowdown="true"
                            arrowup="true"
                        />
                    </FullpageSection>

                    <FullpageSection style={PageStyle}>

                        <StepR style={StepStyle}
                            title={t("Step18.Title")}
                            desc={
                            <div>
                                <p>{t("Step18.Desc")}</p>
                            </div>
                            }
                            img="img/steps/Okta8.png"
                            arrowdown="true"
                            arrowup="true"
                        />
                    </FullpageSection>   

                    <FullpageSection style={PageStyle}>

                        <StepL style={StepStyle}
                            title={t("Step19.Title")}
                            desc={
                            <div>
                                <p>{t("Step19.Desc")}</p>
                            </div>
                            }
                            img="img/steps/Okta6.png"
                            arrowdown="true"
                            arrowup="true"
                        />
                    </FullpageSection>                   
 
                    <FullpageSection style={PageStyle}>

                        <StepR style={StepStyle}
                            title={t("Step20.Title")}
                            desc={
                            <div>
                                <p>{t("Step20.Desc")}</p>
                            </div>
                            }
                            img="img/steps/Zscaler1.png"
                            arrowdown="true"
                            arrowup="true"
                        />
                    </FullpageSection> 

                    <FullpageSection style={PageStyle}>

                        <StepL style={StepStyle}
                            title={t("Step21.Title")}
                            desc={
                            <div>
                                <p>{t("Step21.Desc")}</p>
                            </div>
                            }
                            img="img/steps/Zscaler2.png"
                            arrowdown="true"
                            arrowup="true"
                        />
                    </FullpageSection> 

                    <FullpageSection style={PageStyle}>

                        <StepR style={StepStyle}
                            title={t("Step22.Title")}
                            desc={
                            <div>
                                <p>{t("Step22.Desc")}<b>{t("Shared.finish")}</b>.</p>
                            </div>
                            }
                            img="img/steps/SkipProfile.png"
                            arrowdown="true"
                            arrowup="true"
                        />
                    </FullpageSection> 

                    <FullpageSection style={PageStyle}>

                        <StepL style={StepStyle}
                            title={t("Step23.Title")}
                            desc={
                            <div>
                                <p>{t("Step23.Desc")}</p>
                                <p>{t("Step23.Desc2")}</p>
                            </div>
                            }
                            img="img/steps/Zscaler3.png"
                            arrowdown="true"
                            arrowup="true"
                        />
                    </FullpageSection>
                    
                    <FullpageSection style={PageStyle}>

                        <StepR style={StepStyle}
                            title={t("Step24.Title")}
                            desc={
                            <div>
                                <p>{t("Step24.Desc")}</p>
                                <p>{t("Step24.Desc2")}</p>
                            </div>
                            }
                            img="img/steps/MOEStep14.png"
                            arrowdown="true"
                            arrowup="true"
                        />
                    </FullpageSection>

                 
        </>
        
      );
  };

