import axios from 'axios'
//require('dotenv/config');


axios.defaults.headers.common['Authorization'] = "Bearer " + process.env.REACT_APP_TOKEN;
const api = axios.create({
    baseURL: process.env.REACT_APP_API
})



export default api
